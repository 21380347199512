.header {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
  color: white;
  background-color: rgb(0, 120, 255);
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 36px;
  padding-right: 36px;
}

.logo {
  min-height: 72px;
  height: 10vmin;
  pointer-events: none;
}