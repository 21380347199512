.footer {
  box-sizing: border-box;
  color: white;
  background-color: rgb(0, 120, 255);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  padding-left: 128px;
  padding-right: 128px;
}

.github-link {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 24px;
}

.margin-left-auto {
  margin-left: auto;
}

@media (max-width: 912px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }
  .margin-left-auto {
    margin-left: 0;
    margin-top: 18px;
  }
}