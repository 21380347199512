.app {
  text-align: center;
  font-size: calc(10px + 2vmin);
  background-color: #eef;
  min-height: 100vh;
  width: 100%;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.flex-center {
  margin-top: auto;
  margin-bottom: auto;
}

.flex-bottom {
  margin-top: auto;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.align-items-center {
  align-items: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.position-30 {
  left: 32%;
  position: absolute;
  transform: translateX(-50%);
}

.position-70 {
  left: 42%;
  right: 22%;
  position: absolute;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0px;
}

a {
  color: white;
}

/* a:link {
  color: red;
} */

/* a:visited {
  color: green;
} */

a:hover {
  color: blue;
}

/* a:active {
  color: blue;
} */

@media (max-width: 912px) {
  .pool {
    padding: 24px;
  }

  .flex-container {
    flex-direction: column;
    align-items: center;
  }

  .flex-row {
    justify-content: center;
    gap: 24px;
  }

  .position-30, .position-50, .position-70 {
    position: static;
    transform: none;
    width: 100%;
  }

  .flex-item {
    margin-bottom: 16px;
  }
}