button {
  color: white;
  font-size: 16px;
  background-color: rgb(0, 120, 255);
  transition-duration: 0.4s;
  border-width: 1px;
  border-color: rgb(0, 120, 255);
  border-radius: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 18px;
  padding-right: 18px;
}

button:hover {
  color: rgb(0, 120, 255);
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.3), 0 4px 8px 0 rgba(0,0,0,0.2);
  cursor: pointer;
}