.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.popup {
  top: 50%;
  left: 50%;
  width: 80%;
  height: 80%;
  transform: translate(-50%, -50%);
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0 0 0 1px rgb(0, 120, 255);
  z-index: 1000;
}