.pool {
  background: #fff;
  margin-bottom: 16px;
  margin-left: 24px;
  margin-right: 24px;
  padding: 48px;
  padding-left: 64px;
  padding-right: 64px;
  border-radius: 24px;
  box-shadow: 0 0 0 1px rgb(0, 120, 255);
}

.graph-container {
  position: relative;
  min-width: 90%;
  min-height: 60%;
  max-width: 100%;
}

.day-slider {
  max-width: 80%;
}

.position {
  background: #fff;
  padding: 12px;
  border-radius: 24px;
  box-shadow: 0 0 0 1px rgb(0, 120, 255);
  width: 100%;
  margin-bottom: 12px;
}

.title span {
  display: block;
}

.flex-container {
  display: flex;
  justify-content: space-between;
}

.flex-grow-1 {
  flex-grow: 1;
}

.gap-1 {
  gap: 12px;
}

.justify-center {
  align-content: center;
}

p {
  font-size: 20px;
}

@media (max-width: 464px) {
  p {
    font-size: 18px;
  }
}

@media (max-width: 432px) {
  p {
    font-size: 16px;
  }
}

@media (max-width: 400px) {
  p {
    font-size: 14px;
  }
}